import { type AllIconKeys, Body, Box, Icon } from "@biom3/react";
import { regularVariant } from "@components/QuestTiles/QuestTile/utils";
export type RulesDialogInfoBoxProps = {
  testId: string;
  icons: AllIconKeys[];
  caption: string;
  title: string;
};

export function RulesDialogInfoBox({
  testId,
  icons,
  caption,
  title,
}: RulesDialogInfoBoxProps) {
  return (
    <Box
      testId={`${testId}__RulesDialogInfoBox`}
      sx={{
        backgroundColor: "base.color.translucent.emphasis.100",
        borderRadius: "base.borderRadius.x8",
        textAlign: "center",
        padding: "base.spacing.x3",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flexGrow: 1,
      }}
    >
      <Box testId={`${testId}__icons`}>
        {icons.map((icon, i) => {
          return (
            <Icon
              {...regularVariant(icon)}
              sx={{ width: "base.icon.size.300" }}
              key={icon}
              testId={`${testId}__icons-${i}`}
            />
          );
        })}
      </Box>
      <Body
        size="xxSmall"
        sx={{ color: "base.color.text.body.secondary" }}
        testId={`${testId}__caption`}
      >
        {caption}
      </Body>
      <Body size="xSmall" testId={`${testId}__title`}>
        {title}
      </Body>
    </Box>
  );
}
