import { Box, Stack } from "@biom3/react";
import { RulesDialogInfoBox } from "@components/QuestTiles/QuestTile/RulesDialog/RulesDialogInfoBox/RulesDialogInfoBox";
import {
  type Quest,
  formatDate,
  getPlatformSummary,
} from "@imx-tokens-ts/gems-game/domain";

function Column({
  testId,
  children,
}: { testId: string; children: React.ReactNode }) {
  return (
    <Box
      testId={testId}
      sx={{
        display: "flex",
        flexBasis: "calc(33.33% - 4px)", // gap of 4px base.spacing.x1
        flexGrow: 1, // allows it to grow even if less than 3 columns to take up space
      }}
    >
      {children}
    </Box>
  );
}

export function Summary({ testId, quest }: { testId: string; quest: Quest }) {
  const platFormSummary = getPlatformSummary(quest.availableOn);
  return (
    <Stack
      testId={`${testId}__summary`}
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: "base.spacing.x1",
        mb: "base.spacing.x8",
      }}
    >
      {quest.estimateCompletionTime ? (
        <Column testId={`${testId}-est-time`}>
          <RulesDialogInfoBox
            testId={`${testId}-est-time`}
            icons={["Countdown"]}
            caption="Est. Time"
            title={quest.estimateCompletionTime}
          />
        </Column>
      ) : null}
      {quest.endDate ? (
        <Column testId={`${testId}-end`}>
          <RulesDialogInfoBox
            testId={`${testId}-end`}
            icons={["Calendar"]}
            caption="Quest ends"
            title={formatDate(quest.endDate)}
          />
        </Column>
      ) : null}
      {platFormSummary ? (
        <Column testId={`${testId}-platform`}>
          <RulesDialogInfoBox
            testId={`${testId}-platform`}
            caption="Play on"
            icons={platFormSummary.icons}
            title={platFormSummary.summary}
          />
        </Column>
      ) : null}
    </Stack>
  );
}
