import { useQuestAnalytics } from "@/hooks/useQuestAnalytics";
import {
  Body,
  Box,
  BulletList,
  ConfirmationDialog,
  FramedImage,
  Heading,
  isDualVariantIcon,
} from "@biom3/react";
import { questMediaURL } from "@components/QuestTiles/utils";
import {
  EarningType,
  type Quest,
  formatDate,
} from "@imx-tokens-ts/gems-game/domain";
import { useCallback } from "react";
import { Summary } from "./Summary/Summary";
import { type DialogAction, useGetDialogActions } from "./dialog-actions";

type RulesDialogProps = {
  quest: Quest;
  isVisible: boolean;
  testId: string;
  onClose: () => void;
};

function Caption({ quest, testId }: { testId: string; quest: Quest }) {
  const endDate = quest.endDate;
  const rules = quest.combinedRules || [];
  const showGemDistributionNotes =
    endDate &&
    quest.earningType === EarningType.OffChain &&
    quest.isGemDistributionPending;
  const showSupportText = quest.shouldShowSupport();
  return (
    <>
      <BulletList
        testId={`${testId}__caption`}
        sx={{ "& > ul": { gap: "base.spacing.x2" } }}
      >
        {rules.map((rule, i) => (
          <BulletList.Item key={rule} testId={`${testId}__rule-${i}`}>
            {/* biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation> */}
            <span dangerouslySetInnerHTML={{ __html: rule }} />
          </BulletList.Item>
        ))}
      </BulletList>
      {showGemDistributionNotes && !showSupportText ? (
        <Box testId={`${testId}__gem-distribution`}>
          <Heading
            size="xSmall"
            sx={{ pt: "base.spacing.x6", pb: "base.spacing.x1" }}
          >
            Gem Distribution
          </Heading>
          <Body size="small" sx={{ color: "base.color.text.body.secondary" }}>
            {`Gems will be processed and distributed after ${formatDate(
              endDate,
            )}, following the successful quest completion.
          Thank you for your patience, we will ensure you receive your Gems promptly.`}
          </Body>
        </Box>
      ) : null}
      {showSupportText ? (
        <Box
          testId={`${testId}__support-text`}
          sx={{ pt: "base.spacing.x6", pb: "base.spacing.x1" }}
        >
          <Body size="small" sx={{ color: "base.color.text.body.secondary" }}>
            Completed the quest, but didn’t receive gems? Click the link below
            for support.
          </Body>
        </Box>
      ) : null}
    </>
  );
}

function CaptionLayer3() {
  return (
    <Box sx={{ pt: "base.spacing.x3" }}>
      Trusted by over 4 million users worldwide and the exclusive partner for
      Immutable&apos;s game quests
    </Box>
  );
}

export function RulesDialog({
  quest,
  isVisible,
  testId,
  onClose,
}: RulesDialogProps) {
  const analytics = useQuestAnalytics(quest);
  const actions = useGetDialogActions(quest);
  const isLayer3Quest = quest.hasAction("Layer3");

  const imageName = isLayer3Quest
    ? "layer3-modal.svg"
    : quest.rules?.backgroundMediaImage || quest.backgroundMediaUrl;
  const showSummary = quest.estimateCompletionTime || quest.availableOn?.length;

  const handleActionClick = useCallback(
    (action: DialogAction) => () => {
      analytics.trackCtaClick({
        control: action.label,
        controlType: "Link",
        screen: "QuestTile",
        extras: {
          link: action.link?.toString(),
        },
      });
    },
    [analytics],
  );

  if (!quest.rules && !isLayer3Quest) return null;

  return (
    <ConfirmationDialog
      testId={testId}
      visible={isVisible}
      outsideClicksClose
      escapeKeyClose
      onCloseModal={onClose}
    >
      <ConfirmationDialog.Content
        textAlign="left"
        sx={{
          maxWidth: { default: "100dvw", small: "430px", medium: "500px" },
          backgroundColor: "base.color.neutral.800",
        }}
      >
        <ConfirmationDialog.Content.CloseButtCon
          testId={`${testId}__close-button`}
          onClick={onClose}
        />
        <ConfirmationDialog.Content.Image
          testId={`${testId}__image`}
          imageUrl={questMediaURL(imageName).toString()}
          aspectRatio={4}
          responsiveSizes={[370, 450, 512, 720, 1024]}
          sx={{ height: "161px" }}
        />

        <ConfirmationDialog.Content.Title
          testId={`${testId}__title`}
          size="small"
          sx={{
            marginBottom: "base.spacing.x4",
            position: "relative",
          }}
        >
          <FramedImage
            imageUrl={questMediaURL(quest.logo).toString()}
            testId={`${testId}__logo`}
            size="large"
            sx={{
              position: "absolute",
              left: "0px",
              top: "-58px",
            }}
          />
          {isLayer3Quest
            ? "Taking you to Layer3 Quest Platform"
            : `How to earn ${quest.gemsAmount} gems`}
        </ConfirmationDialog.Content.Title>
        <ConfirmationDialog.Content.Caption
          testId={`${testId}__caption`}
          sx={{ pt: "0px" }}
        >
          {showSummary ? <Summary testId={testId} quest={quest} /> : null}
          {isLayer3Quest ? (
            <CaptionLayer3 />
          ) : (
            <Caption quest={quest} testId={`${testId}`} />
          )}
        </ConfirmationDialog.Content.Caption>
        {actions.map((action) => {
          const buttonProps = action.link
            ? {
                rc: (
                  <a
                    href={action.link.toString()}
                    target="_blank"
                    rel="external noreferrer"
                    referrerPolicy="origin"
                    onClick={handleActionClick(action)}
                  />
                ),
              }
            : { onClick: handleActionClick(action) };
          return (
            <ConfirmationDialog.Content.Button
              key={`confirmation-dialog-${action.label}`}
              testId={`${testId}__${action.action}`}
              size="large"
              variant={action.action === "Support" ? "tertiary" : "primary"}
              {...buttonProps}
            >
              <ConfirmationDialog.Content.Button.Icon
                {...(isDualVariantIcon(action.icon)
                  ? { icon: action.icon, iconVariant: "regular" }
                  : { icon: action.icon })}
                sx={{ w: "base.icon.size.200" }}
              />
              {action.label}
            </ConfirmationDialog.Content.Button>
          );
        })}
      </ConfirmationDialog.Content>
    </ConfirmationDialog>
  );
}
