import { appConfig } from "@constants";
import {
  type QuestMediaType,
  QuestTileFilterOption,
} from "@imx-tokens-ts/gems-game/domain";

const videoExtensions = ["m3u8"];
const endsWith = (suffixes: string[], filename: string) =>
  suffixes.some((ext) => filename.endsWith(ext));

export const questMediaURL = (filename: QuestMediaType | URL) =>
  filename instanceof URL
    ? filename
    : endsWith(videoExtensions, filename)
      ? new URL(`${appConfig.ASSET_BASE_URL}/videos/quests/${filename}`)
      : new URL(`${appConfig.ASSET_BASE_URL}/images/quests/${filename}`);

export const mainAndSideFilterOptions = [
  QuestTileFilterOption.All,
  QuestTileFilterOption.Live,
  QuestTileFilterOption.ComingSoon,
  QuestTileFilterOption.Ended,
  QuestTileFilterOption.MyCompletedQuests,
];
