import type { AllIconKeys } from "@biom3/react";
import type { Action, Quest } from "@imx-tokens-ts/gems-game/domain";
import {
  type Options,
  type UserAgent,
  actionToIconMapping,
  detectUserAgent,
} from "../utils";

export type DialogOptions = Pick<Options, "userAgent">;

export type DialogAction = {
  action: Action;
  label: string;
  icon: AllIconKeys;
  link: URL;
};

export const actionToLabelMapping = {
  PlayGame: "Play game",
  RegisterNow: "Register now",
  Support: "Contact support",
  GooglePlay: "Play on Google Play",
  AppleAppStore: "Play on App Store",
  Layer3: "Continue to Layer3",
  TradeNow: "Trade now",
  TakeQuiz: "Take quiz",
  VerifyEligibility: "Verify eligibility",
} as Record<Action, string>;

function getPossibleDialogActions(userAgent: UserAgent): Action[] {
  if (userAgent === "iOS") {
    return ["TradeNow", "AppleAppStore", "PlayGame", "RegisterNow"];
  }
  if (userAgent === "Android") {
    return ["TradeNow", "GooglePlay", "PlayGame", "RegisterNow"];
  }
  return ["TradeNow", "PlayGame", "AppleAppStore", "GooglePlay", "RegisterNow"];
}

function getDialogPrimaryActions(
  quest: Quest,
  opts: DialogOptions,
): DialogAction[] {
  if (quest.actions.Layer3) {
    return [
      {
        action: "Layer3",
        icon: actionToIconMapping.Layer3,
        label: actionToLabelMapping.Layer3,
        link: quest.actions.Layer3,
      },
    ];
  }

  if (quest.actions.TakeQuiz) {
    return [
      {
        action: "TakeQuiz",
        icon: actionToIconMapping.TakeQuiz,
        label: actionToLabelMapping.TakeQuiz,
        link: quest.actions.TakeQuiz,
      },
    ];
  }
  if (quest.actions.VerifyEligibility) {
    return [
      {
        action: "VerifyEligibility",
        icon: actionToIconMapping.VerifyEligibility,
        label: actionToLabelMapping.VerifyEligibility,
        link: quest.actions.VerifyEligibility,
      },
    ];
  }
  const possibleActions: Action[] = getPossibleDialogActions(opts.userAgent);
  const questActions = quest.actions;
  const actions = possibleActions
    .filter((action) => questActions[action])
    .map((action) => {
      return {
        action: action,
        icon: (quest.ctaIcon as AllIconKeys) || actionToIconMapping[action],
        // biome-ignore lint/style/noNonNullAssertion: <explanation>
        link: questActions[action]!,
        label: quest.ctaText || actionToLabelMapping[action],
      };
    });

  // we only want to show 1 primary action depending on device
  // but we play game isn't present, we have to show 2 actions for android and ios
  const possibleActionCount = quest.actions.PlayGame ? 1 : 2;
  return actions.slice(0, possibleActionCount);
}

export function getDialogActions(
  quest: Quest,
  opts: DialogOptions,
): DialogAction[] {
  const actions: DialogAction[] = getDialogPrimaryActions(quest, opts);

  if (quest.shouldShowSupport() && quest.actions.Support) {
    actions.push({
      action: "Support",
      label: actionToLabelMapping.Support,
      icon: actionToIconMapping.Support,
      link: quest.actions.Support,
    });
  }

  return actions;
}

export function useGetDialogActions(quest: Quest): DialogAction[] {
  const opts = {
    userAgent: detectUserAgent(
      typeof navigator !== "undefined" ? navigator.userAgent : "Other",
    ),
  };

  return getDialogActions(quest, opts);
}
