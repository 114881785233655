import type { Quest, QuestTileType } from "@/domain/gems-game/domain";
import { trackLegacyEvent } from "@analytics";

type Overrides = {
  control?: string;
  controlType?: string;
  screen: string;
  extras?: Record<string, unknown>;
};

export function useTileAnalytics(tileId: string, tileType: QuestTileType) {
  const trackCtaClick = ({
    control = "UndefinedControl",
    controlType = "Button",
    screen,
    extras,
  }: Overrides) =>
    trackLegacyEvent({
      screen,
      userJourney: tileId,
      action: "Pressed",
      control,
      controlType,
      extras: {
        tileType,
        tileId,
        userJourney: "Quests",
        control,
        controlType,
        ...extras,
      },
    });
  return {
    trackCtaClick,
  };
}

export function useQuestAnalytics(quest: Quest) {
  const { type: tileType, analyticsId } = quest;
  const questDetails = {
    questId: quest.id,
    gameName: quest.caption,
    gameId: quest.gameId,
    questGems: quest.gemsAmount,
  };

  const trackCtaClick = ({
    control = "PlayGame",
    controlType = "Button",
    screen = "QuestTile",
    extras,
  }: Overrides) => {
    const controlPascal = toPascalCase(control);
    trackLegacyEvent({
      screen,
      userJourney: analyticsId,
      action: "Pressed",
      control: controlPascal,
      controlType,
      extras: {
        tileType,
        questType: analyticsId,
        userJourney: "Quests",
        controlType,
        controlLabel: controlPascal,
        ...questDetails,
        ...extras,
      },
    });
  };

  return {
    trackCtaClick,
  };
}

function toPascalCase(input: string): string {
  return input
    .split(" ") // Split the string by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(""); // Join them back without spaces
}
