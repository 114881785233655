import {
  type AllDualVariantIconKeys,
  type AllIconKeys,
  type AllSingleVariantIconKeys,
  dualVariantIcons,
  singleVariantIcons,
} from "@biom3/react";
import type {
  Action,
  Quest,
  QuestActions,
} from "@imx-tokens-ts/gems-game/domain";
import type {
  PrimaryTileAction,
  SecondaryTileAction,
} from "./actions/tile-actions";

export type UserAgent = "iOS" | "Android" | "Other";

export type Options = {
  isMediumScreenOrAbove: boolean;
  isLargeScreenOrAbove: boolean;
  isQuestComplete: boolean;
  userAgent: UserAgent;
};

export const actionToIconMapping: Record<Action, AllIconKeys> = {
  PlayGame: "PlayGame",
  RegisterNow: "EmailAddress",
  Support: "SupportChat",
  Discord: "Discord",
  Layer3: "Layer3",
  Youtube: "YouTube",
  Twitter: "X",
  Rules: "Document",
  Telegram: "Telegram",
  GooglePlay: "GooglePlayStore",
  AppleAppStore: "Apple",
  TradeNow: "Tokens",
  TakeQuiz: "Edit",
  VerifyEligibility: "JumpTo",
};

export function detectUserAgent(userAgent: string): UserAgent {
  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "Other";
}

export function getSecondaryActionsForGame(
  quest: Quest,
  opts: Options,
): SecondaryTileAction[] {
  const actions = quest.actions;
  const { userAgent } = opts;

  // if android/ios and has play game, and quest is in coming soon or live state, then play game is the primary action
  if (
    (quest.isComingSoon() || quest.isLive()) &&
    (userAgent !== "Other" || actions.PlayGame)
  ) {
    return [];
  }

  // for quest in completed, ended states
  let possibleGameLinks: Action[] = [];

  if (userAgent === "Other") {
    // if on web and has play game, show play game as the only action
    // if not, fall back to showing apple app store, google play
    possibleGameLinks = actions.PlayGame
      ? ["PlayGame"]
      : ["AppleAppStore", "GooglePlay"];
  } else if (userAgent === "iOS") {
    possibleGameLinks = actions.AppleAppStore
      ? ["AppleAppStore"]
      : ["PlayGame"];
  } else if (userAgent === "Android") {
    possibleGameLinks = actions.GooglePlay ? ["GooglePlay"] : ["PlayGame"];
  }

  return possibleGameLinks
    .filter((action) => actions[action])
    .map((action) => {
      return {
        action,
        icon: actionToIconMapping[action],
        link: actions[action],
      };
    });
}

export function getSecondaryActionsForSocials(
  actions: QuestActions,
  count: number,
): SecondaryTileAction[] {
  // this also defines the order of priority for the social actions
  const possibleSocialActions: Action[] = [
    "Discord",
    "Twitter",
    "Youtube",
    "Telegram",
  ];
  let socialActions: SecondaryTileAction[] = possibleSocialActions
    .filter((action) => actions[action])
    .map((action) => {
      return {
        action: action,
        icon: actionToIconMapping[action],
        link: actions[action],
      };
    });

  if (socialActions.length > count) {
    socialActions = socialActions.slice(0, count);
  }

  return socialActions;
}

function getPossibleAvailableActions(userAgent: UserAgent): Action[] {
  if (userAgent === "iOS")
    return [
      "TradeNow",
      "AppleAppStore",
      "PlayGame",
      "RegisterNow",
      "TakeQuiz",
      "VerifyEligibility",
    ];
  if (userAgent === "Android")
    return [
      "TradeNow",
      "GooglePlay",
      "PlayGame",
      "RegisterNow",
      "TakeQuiz",
      "VerifyEligibility",
    ];
  return [
    "TradeNow",
    "PlayGame",
    "RegisterNow",
    "TakeQuiz",
    "VerifyEligibility",
  ];
}

function getLabelForAction(action: Action, quest: Quest): string {
  if (quest.ctaText) return quest.ctaText;
  if (action === "VerifyEligibility") return "Verify eligibility";
  if (action === "TakeQuiz") return "Take quiz";
  if (quest.isTakeQuizQuest()) return "View Game";
  if (action === "TradeNow") return "Trade";
  if (action === "RegisterNow") return "Register";
  return quest.isImmutablePlayLink() ? "View game" : "Play";
}

// used by featured/main/side quests that are not layer3
export function getAvailablePrimaryAction(
  quest: Quest,
  opts: Options,
): PrimaryTileAction | null {
  // this is in order of priority
  const possibleAvailableActions = getPossibleAvailableActions(opts.userAgent);
  const action = possibleAvailableActions.find(
    (action) => quest.actions[action],
  );
  if (!action) return null;

  return {
    action,
    label: getLabelForAction(action, quest),
    icon: (quest.ctaIcon as AllIconKeys) || actionToIconMapping[action],
    link: quest.actions[action],
  };
}

function isDualVariantIcon(icon: AllIconKeys): icon is AllDualVariantIconKeys {
  return dualVariantIcons.includes(icon as AllDualVariantIconKeys);
}

function isSingleVariantIcon(
  icon: AllIconKeys,
): icon is AllSingleVariantIconKeys {
  return singleVariantIcons.includes(icon as AllSingleVariantIconKeys);
}

export const regularVariant = (
  icon: AllIconKeys,
): { icon: AllDualVariantIconKeys } | { icon: AllSingleVariantIconKeys } => {
  const val = isDualVariantIcon(icon)
    ? { icon }
    : isSingleVariantIcon(icon)
      ? { icon }
      : null;
  if (!val) throw new Error(`Invalid icon ${icon}`);
  return val;
};
